module.exports = {
  assetPrefix: `/animeawards`,
  trailingSlash: `ignore`,
  siteMetadata: {
    baseUrl: `https://crunchyroll.com`,
    url: `https://crunchyroll.com/animeawards`,
    title: `The Anime Awards - Crunchyroll`,
    description: `The Anime Awards is a celebration of Japan's amazing medium. Vote for Anime of the Year, Best Animation and more! Winners announced live on Feb 19, 2021.`,
    banner: {
      url: `https://crunchyroll.com/animeawards/fbbanner_23.png`,
      width: `1600`,
      height: `900`,
    },
  },
  plugins: [
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-plugin-emotion`,
    `gatsby-plugin-react-helmet`,
    `gatsby-transformer-sharp`,
    {
      resolve: `gatsby-plugin-sharp`,
      options: {
        defaults: {
          formats: [`auto`, `webp`],
          quality: 80,
        },
      },
    },
    `gatsby-plugin-image`,
  ],
}
